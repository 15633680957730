import { Fragment, useMemo } from 'react';

import { BorderBtn } from '@playbooks/interface/buttons';
import { Small } from '@playbooks/interface/fonts';

const BtnPagination = ({ meta, params, setParams, taskRunning, onClick }) => {
	// Computed
	const computed = useMemo(() => {
		const used = (meta.page + 1) * meta.pageSize || 0;
		const remaining = meta.totalRecords - used || 0;
		const total = Math.ceil(meta.totalRecords / (meta.pageSize || 10000000)) || 0;
		return { used, remaining, total };
	}, [JSON.stringify(meta)]);

	// Render
	return (
		<Fragment>
			{meta.page + 1 < computed.total ? (
				<BorderBtn
					size='lg'
					prevIcon='arrow-down'
					taskRunning={taskRunning}
					onClick={() => onClick(meta.page + 1)}
					className='w-full'>
					{`Load more (${computed.remaining} more)`}
				</BorderBtn>
			) : (
				<Small>End of results</Small>
			)}
		</Fragment>
	);
};

export { BtnPagination };
